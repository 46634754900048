<template>
  <v-container>
    <v-stepper v-model="stepperStep">
      <v-stepper-header>
        <v-stepper-step color="orange" :complete="stepperStep > 1" step="1">Получение МК</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step color="orange" :complete="stepperStep > 2" step="2">Проверка МК</v-stepper-step>
      </v-stepper-header>
  
      <v-stepper-items>
        <v-stepper-content step="1">
          <div>
            <v-row justify="center" class="pr-15 pl-3">
              <v-col cols="12" align-self="center" class="text-h5 px-10 mb-5">
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col>
                    <v-text-field outlined label="Номер билета без кода" v-model="numberTicket"
                      style="height: 3.5rem"></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
    
            <v-col cols="12" class="pr-15 pl-3">
              <v-row>
                <v-col cols="4" >
                  <v-text-field outlined label="Номер телефона" v-model="phoneNumber" />
                </v-col>
                <v-col cols="4">
                  <v-text-field outlined label="Адрес почты" v-model="email" />
                </v-col>
                <v-col cols="4">
                  <custom-date-picker v-model="date.Start" mask="##.##.####" no-title outlined format="dd.MM.yyyy">
                  </custom-date-picker>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" md="3">
                  <v-btn block dark color="orange" :disabled="isDownloadFile" style="width: 100%; height: 3.3rem"
                          @click="clearForm">Очистить форму
                  </v-btn>
                </v-col>
                <v-spacer/>
                <v-col cols="6" md="3" class="text-center">
                  <v-btn @click="getReceiptLog" color="orange" :disabled="isFormValid"
                    style="width: 100%; height: 3.3rem">
                    Получить
                  </v-btn>
                </v-col>
              </v-row>
              
            </v-col>
          </div>
    
          </v-stepper-content>
  
          <v-stepper-content step="2">
            <div class="pdf-viewer mb-2 mt-2">
              <div id="mypdf" class="pdf-viewer"></div>
            </div>
            <v-col cols="12" align-self="center">
              <div v-if="!files.length" class="text-h5 mb-2">
                <v-checkbox label="Загрузить чек" v-model="isDownloadFile"
                color="orange" />
              </div>
              <file-input v-if="isDownloadFile" ref="fileInput" v-model="files" accept=".pdf" id="liner"  @change-value="updateFiles" />
            </v-col>

            <v-col cols="12" class="pr-15 pl-3 pb-10">
                <v-row>
                  <v-btn v-if="isDownloadFile" color="orange" @click="uploadCheck" class="mx-2">
                    Загрузить чек
                  </v-btn>
                </v-row>
            </v-col>

            <v-btn color="orange" @click="sendSms" class="mx-2" :disabled="isFormValid">
              Отправить смс
            </v-btn>
            <v-btn @click="stepperStep--" text class="mx-2"> Назад </v-btn>
          </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    
      <v-snackbar
        v-model="isSend"
        color="warning"
        :timeout="5000"
        content-class="text-center"
        text
      >
        {{ "Текст смс отправлен" }}
      </v-snackbar>
      <overlay-loader overlay :loading="loading"></overlay-loader>
  </v-container>

</template>

<script>
import FileInput from "@/components/UI/FileInput.vue";
import Snackbar from "@/components/UI/Snackbar.vue";
import DateFormat from "@/mixins/DateFormat";
import CustomDatePicker from "@/components/UI/CustomDatePicker.vue";
import PDFObject from 'pdfobject';
import OverlayLoader from "@/components/UI/OverlayLoader.vue";

export default {
  mixins: [DateFormat],
  components: { 
    FileInput,
    Snackbar,
    CustomDatePicker,
    OverlayLoader
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: function() {
    return {
      stepperStep: 1,
      date: {
        Start: new Date(),
      },
      numberTicket: "",
      phoneNumber: "",
      email: "",
      isDownloadFile: false,

      loading: false,

      receiptLog: {
        pdf: undefined,
        id: "",
      },

      isSend: false,
      files: [],
      arrayBase64: []
    }
  },
  methods: {
    updateFiles(newFiles) {
        this.files = newFiles; 
    },

    async getReceiptLog() {
      this.loading = true;
      this.arrayBase64 = [];
      try {
        let resp = await this.$axios.post(this.$enums.Endpoints.Admin.Receipt.GetReceiptPdf, {
          numberTicket: this.numberTicket,
          phoneNumber: this.phoneNumber,
          email: this.email,
          docDate: this.date.Start
        });
        this.stepperStep++;
        
       this.receiptLog = {
        pdf: resp.data.pdf,
        id: resp.data.id,
      };

        this.arrayBase64.push(this.receiptLog.pdf);
        this.showEmbedPdf();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async sendSms()
      {
        try {
          let resp = await this.$axios.post(this.$enums.Endpoints.Admin.Receipt.SmsSender, {
              phoneNumber: this.phoneNumber,
              email: this.email,
              numberTicket: this.numberTicket,
              pdf: this.receiptLog.pdf,
              id: this.receiptLog.id
          });

          this.isSend = true;
          this.stepperStep++;

          } catch (e) {
          console.error(e);
          } finally {
            this.clearForm();
          }
      },

      async uploadCheck() {
          this.loading = true;
          if (this.files.length > 0) {
              const reader = new FileReader();
              reader.readAsDataURL(this.files[0]);
              reader.onload = async () => {
              const base64 = reader.result.split(',')[1]; 
              this.arrayBase64.push(base64);
              try {
                let resp = await this.$axios.post(this.$enums.Endpoints.Admin.Receipt.MergeFiles, {
                  arrayBase64: this.arrayBase64
                });

                this.receiptLog.pdf = resp.data;
                this.$refs.fileInput.clearFiles();

              } catch (e) {
                console.error(e);
              } finally {
                this.loading = false;
                this.showEmbedPdf();
              }
            };
            reader.onerror = (error) => {
              console.error('Error converting file to Base64:', error);
              this.loading = false;
            };
          }
        else {
            this.showEmbedPdf();
          }
      },

    showEmbedPdf(){
      const options = {
          title: "Маршрут-квитанция",
        };
        if (this.receiptLog.pdf != undefined)
          PDFObject.embed(
            'data:application/pdf;base64,' + this.receiptLog.pdf,
            '#mypdf',
            options
          );
      },

      clearForm(){
        this.numberTicket = "";
        this.phoneNumber = "";
        this.email = "";
        this.stepperStep = 1;
        this.files = [];
        this.arrayBase64 = [];
        this.date.Start = new Date();
      },
    },

  computed: {
    isFormValid() {
      return (this.numberTicket.length < 4  || (this.email.length <= 0 && this.phoneNumber.length <= 0));
    },
  },
}
</script>

<style scoped>
.pdf-viewer {
  height: 800px;
}

.stepper {
  overflow: visible;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

.localfix .v-slide-group__prev.v-slide-group__prev--disabled,
.localfix .v-slide-group__next.v-slide-group__next--disabled {
  display: none !important;
}
</style>