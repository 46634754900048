<template>
  <v-container>
    <v-row align="center" justify="space-between">
      <v-col cols="12" md="5">
        <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="formattedDateRange"
                label="Выберите диапазон дат"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="dateRange"
              range
              scrollable
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="3">
        <v-text-field
            v-model="minRepeatCount"
            label="Мин. повторные"
            type="number"
            :rules="[value => value > 0 || 'Значение должно быть больше 0']"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4" style="display: flex; align-items: center;">
        <v-btn
            @click="applyDateFilter"
            color="orange"
            style="width: 100%; height: 3.3rem"
            :disabled="minRepeatCount <= 0"
        >
          Показать отчет
        </v-btn>
      </v-col>
    </v-row>

    <v-col
        v-for="(group, index) in filteredSmsData"
        :key="index"
    >
      <h3>Номер телефона: {{ group.phoneNumber }}</h3>
      <v-data-table
          :headers="headers"
          :items="group.records"
          :items-per-page="-1"
          hide-default-footer
          :loading="smsMonitorLoading"
          class="elevation-1 bordered-table"
      >
        <template v-slot:item.createdAt="{ item }">
          {{ new Date(item.createdAt).toLocaleString() }}
        </template>
      </v-data-table>
    </v-col>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'SmsMonitoring',
  props: {
    smsMonitorLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const today = new Date();
    const lastWeek = new Date();
    lastWeek.setDate(today.getDate() - 7);

    return {
      smsData: [],
      filteredSmsData: [],
      minRepeatCount: 1,
      headers: [
        {text: 'Имя пассажира', value: 'passengerName', class: 'table-header-name'},
        {text: 'Почта учетной записи', value: 'accountEmail', class: 'table-header-email'},
        {text: 'Дата и время формирования МК', value: 'createdAt', class: 'table-header-date'},
        {text: 'Номер билета в запросе при формировании МК', value: 'ticketNumber', class: 'table-header-ticket'},
        {text: 'Статус', value: 'status', class: 'table-header-status'},
      ],
      dateRange: [lastWeek.toISOString().substr(0, 10), today.toISOString().substr(0, 10)],
      dateMenu: false,
    };
  },
  computed: {
    formattedDateRange() {
      const [start, end] = this.dateRange;
      if (start && end) {
        return `${new Date(start).toLocaleDateString()} - ${new Date(end).toLocaleDateString()}`;
      }
      return 'Выберите диапазон дат';
    },
  },
  methods: {
    async fetchData() {
      this.smsMonitorLoading = true;
      const [startDate, endDate] = this.dateRange.map(date => new Date(date).toISOString());
      try {
        const response = await axios.get(this.$enums.Endpoints.Admin.MK.GetMKReport, {
          params: {
            startDate,
            endDate,
            minRepeatCount: this.minRepeatCount
          }
        });
        this.smsData = response.data;
        this.filteredSmsData = response.data;
      } catch (error) {
        console.error('Ошибка при запросе на сервер', error);
      } finally {
        this.smsMonitorLoading = false;
      }
    },
    applyDateFilter() {
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.bordered-table {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px;
}
</style>
