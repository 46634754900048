<template>
  <v-card flat class="px-5 pb-5" style="margin-top: 20px;">
    <v-row justify="center" align="center" class="pr-15 pl-4">
      <v-col cols="12">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
                outlined
                label="Номер билета без кода"
                v-model="requestData.ticketNumber"
                style="height: 3.5rem"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-select
                outlined
                :items="[$enums.MethodEnum.ENTER_KKT_NUMBER, $enums.MethodEnum.SELECT_ACCOUNT]"
                label="Выбор метода"
                v-model="selectionMethod"
                style="height: 3.5rem"
            ></v-select>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
                v-if="selectionMethod === $enums.MethodEnum.ENTER_KKT_NUMBER"
                outlined
                label="Номер ККТ"
                v-model="requestData.regId"
                style="height: 3.5rem"
            ></v-text-field>

            <input-account
                v-else
                v-model="requestData.accountId"
                label="Выберите аккаунт"
                :endpoint="$enums.Endpoints.Account.SearchWithoutRole"
            ></input-account>
          </v-col>

          <v-col cols="12" md="4">
            <custom-date-picker v-model="requestData.docDate" no-title outlined format="dd.MM.yyyy">
            </custom-date-picker>
          </v-col>

          <v-col cols="12" md="4">
            <v-btn
                @click="getCheck"
                :loading="isDownload"
                color="orange"
                :disabled="!isFormValid"
                style="width: 100%; height: 3.3rem"
            >
              Получить чек
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { downloadFile } from "@/mixins/utils.js";
import InputAccount from "@/components/UI/InputAccount.vue";
import DateFormat from "@/mixins/DateFormat";
import CustomDatePicker from "@/components/UI/CustomDatePicker.vue";

export default {
  mixins: [DateFormat],
  components: 
  { 
    InputAccount,
    CustomDatePicker
  },

  data() {
    return {
      requestData: {
        ticketNumber: "",
        regId: "",
        accountId: null,
        docDate: new Date()
      },
      selectionMethod: this.$enums.MethodEnum.SELECT_ACCOUNT,
      isDownload: false,
    };
  },

  methods: {
    async getCheck() {
      this.isDownload = true;
      const newTicketNumber = this.requestData.ticketNumber.replace(" ", "");

      const formattedDate = this.requestData.docDate.toISOString();
      this.requestData.docDate = new Date(formattedDate);

      switch (this.selectionMethod) {
        case this.$enums.MethodEnum.SELECT_ACCOUNT:
          if (!this.requestData.accountId) {
            console.error("Нет привязанного KKT для выбранного аккаунта");
            this.isDownload = false;
            return;
          }
          break;

        case this.$enums.MethodEnum.ENTER_KKT_NUMBER:
          this.requestData.regId = this.requestData.regId.replace(" ", "");
          if (!this.requestData.regId) {
            console.error("Номер ККТ не указан");
            this.isDownload = false;
            return;
          }
          break;

        default:
          console.error("Неизвестный метод выбора");
          this.isDownload = false;
          return;
      }

      try {
        let resp = await this.$axios.post(this.$enums.Endpoints.Admin.Check.CheckInfo, this.requestData);
        let uint8Array = new Uint8Array(
            atob(resp.data).split("").map((c) => c.charCodeAt(0))
        );
        downloadFile(uint8Array, {name: `${newTicketNumber}_check.pdf`});
      } catch (error) {
        console.error("Ошибка при получении чека:", error.response ? error.response.data : error);
      } finally {
        this.isDownload = false;
      }
    },
  },

  watch: {
    selectionMethod(newMethod) {
      if (newMethod === this.$enums.MethodEnum.SELECT_ACCOUNT) {
        this.requestData.regId = "";
      } else {
        this.requestData.accountId = null;
      }
    },
    'requestData.regId'(newRegId) {
      if (newRegId && this.selectionMethod === this.$enums.MethodEnum.SELECT_ACCOUNT) {
        this.requestData.accountId = null;
      }
    },
    'requestData.accountId'(newAccountId) {
      if (newAccountId && this.selectionMethod === this.$enums.MethodEnum.ENTER_KKT_NUMBER) {
        this.requestData.regId = "";
      }
    }
  },

  computed: {
    isFormValid() {
      return (
          this.requestData.ticketNumber.length > 0 &&
          (this.selectionMethod === this.$enums.MethodEnum.ENTER_KKT_NUMBER
              ? this.requestData.regId.length > 0
              : this.requestData.accountId)
      );
    },
  },
};
</script>

<style scoped></style>
